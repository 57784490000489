import React, {Component} from "react";
import Navigation from "./Navigation.js";

import Scrollchor from 'react-scrollchor';

const Header = () => {

	return (
		<header id="mainHeader">
			<Navigation />
			<div className="content paddingTop">
				<h1>Hi,<br/>
					I'm Daniel Wahl
				</h1><br /><br/>
				<h2>
					Freelance Frontend Web Developer<br/>
					and Photographer<br/>
				</h2>
				<h2>ready to <span className="accent">create_</span></h2>
			</div>
			<div className="content">
				<div className="grid half-half widthHalf paddingTop">
					<Scrollchor to="#projects"><button className="empty primary fullWidth">Projects</button></Scrollchor>
					<Scrollchor to="#contact"><button className="full primary fullWidth">Get in touch</button></Scrollchor>
				</div>
			</div>
		</header>
	);

}

export default Header

/*
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waves">
					<path fill="#232323" fillOpacity="1" d="M0,288L40,288C80,288,160,288,240,293.3C320,299,400,309,480,309.3C560,309,640,299,720,272C800,245,880,203,960,208C1040,213,1120,267,1200,245.3C1280,224,1360,128,1400,80L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
				</svg>
 */