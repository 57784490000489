import React from "react";
import Scrollchor from 'react-scrollchor';
import {Fade} from 'react-reveal';

const About = () => {

	return (
		<>
			<section id="about">
				<div className="content">
					<h2>Who I am</h2>
					<div className="grid seventy-thirty"><div>
						<ul className="about__list">
							<li><a className="accentHover" href="https://www.linkedin.com/in/daniel-wahl-206476127">LinkedIn: Daniel Wahl</a></li>
							<li><a className="accentHover" href="https://instagram.com/danielwahl.lu">Instagram: @danielwahl.lu</a></li>
							{/*<li><a className="accentHover" href="https://instagram.com/whaledev">Instagram: @whaledev</a></li>*/}
							<li><a className="accentHover" href="https://github.com/DanielWahl">Github: @DanielWahl</a></li>
							<li><a className="accentHover" href="https://www.malt.de/profile/danielwahl">Malt: @danielwahl</a></li>
							<li><a className="accentHover" href="https://500px.com/daniel12310">500px: @daniel12310</a></li>
						</ul>
						<p className="bigger">

							Hi there. I'm Daniel, a freelance developer and photographer coming from the small country of Luxembourg.<br />
							<br />
							I have been developing since a young age and in the past years, I've worked with a lot of talented developers and designers,
							who helped me grow my passion and skills.<br />
							I've learned what any good website and web applications need and have raised my standards accordingly.<br />
							<br />
							In my free time I'm playing drums in a luxembourgish alternative rock band called <a href="https://www.instagram.com/ddcband/" target="_blank">Deep Dive Culture</a><br />
							and also use the beauty of nature to pursue my hobby for landscape photography.<br />
							<br />
							If you are interested in getting in touch with me for any project, go to <Scrollchor to="#contact" className="accentHover">Contact</Scrollchor>.<br />
							<br />
							My Tech-stack/Skills: React, Next.js, SASS/CSS, Typescript, JS, PHP, Node, React Native, Wordpress (PHP, Twig), Typo3, NeosCMS (AFX, Fusion), RestAPI/GraphQL, SQL/NoSQL, Docker, Git
						</p>
					</div>
						<img src="assets/images/profile2.jpg" alt="Portrait" />
					</div>
				</div>
			</section>
		</>
	);

}
export default About