import React, {Component} from 'react';
import Home from './routes/Home';
import Impressum from './routes/Impressum';
import "./style/style.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Fetch from './helpers/Fetch';
import Loader from 'react-loader-spinner';

export default class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading:	true,
			projects:	null,
		}
	}

	async componentDidMount() {
		let projects = await Fetch.fetchProjects();

		this.setState({
			isLoading: false,
			projects: projects,
		});
	}



	render() {
		let {isLoading, projects} = this.state;

		if(isLoading) {
			return (
				<div style={{width: '100vw', height: '100vh', margin: '45vh auto', textAlign: 'center'}}>
					<Loader
						type="BallTriangle"
						color="#2274A5"
						height={100}
						width={100}

					/>
				</div>
			)
		}

        return (
            <Router>
				<Switch>
					<Route exact path="/">
						<Home projects={projects}/>
					</Route>
					<Route exact path="/impressum">
						<Impressum />
					</Route>
				</Switch>
            </Router>
    	);
    }
}

/*<Route exact path="/impressum">
						<Impressum />
					</Route>*/