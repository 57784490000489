import React from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contact from "../components/Contact";
import Projects from "../components/Projects";
import About from "../components/About";
import Photography from "../components/Photography";

const Home = props => {

	return (
		<div className="global">
			<Header />
			<main>
				<Projects {...props} />

				<About/>

				<Photography/>

				<Contact />
			</main>
			<Footer />
		</div>
	);

}
export default Home