import React, {Component} from "react";
import Footer from '../components/Footer';
import Navigation from "../components/Navigation";

const Impressum = () => {

	return (
		<div className="global">
			<Navigation />
			<main>
				<section id="impressum">
					<div className="content">
						<h1>Impressum</h1>
						<p>Angaben gemäß § 5 TMG</p>
						<p><br />
							Daniel Wahl <br />
							genauere Adresse auf Anfrage per Mail<br />
							L-1870 Luxembourg<br />
						</p><br />
						<p>
							<strong>Vertreten durch: </strong><br />
							Daniel Wahl<br />
						</p>
						<p><br />
							<strong>Kontakt:</strong> <br />
							Telefon: auf Anfrage per Mail<br />
							E-Mail: <a href='contact.php'>contact@danielwahl.lu</a><br />
						</p>
						<p><br />
							<strong>Mehrwertsteuer-ID: </strong> <br />
							 LU32170660<br /><br />
						</p>

							<h1 className="adsimple-311157787">Datenschutzerklärung</h1>
							<h2 className="adsimple-311157787">Datenschutz</h2>
							<p>Wir haben diese Datenschutzerklärung (Fassung 01.03.2020-311157787) verfasst, um Ihnen gemäß der Vorgaben der <a className="adsimple-311157787" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311157787" target="_blank" rel="noopener noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
							<p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
							<h2 className="adsimple-311157787">Cookies</h2>
							<p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />
								Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
							<h3 className="adsimple-311157787">Was genau sind Cookies?</h3>
							<p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
							<p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
							<p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
							<p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
							<p>So können zum Beispiel Cookie-Daten aussehen:</p>
							<ul className="adsimple-311157787">
								<li className="adsimple-311157787">Name: _ga</li>
								<li className="adsimple-311157787">Ablaufzeit: 2 Jahre</li>
								<li className="adsimple-311157787">Verwendung: Unterscheidung der Webseitenbesucher</li>
								<li className="adsimple-311157787">Beispielhafter Wert: GA1.2.1326744211.152311157787</li>
							</ul>
							<p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
							<ul className="adsimple-311157787">
								<li className="adsimple-311157787">Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
								<li className="adsimple-311157787">Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
								<li className="adsimple-311157787">Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
							</ul>
							<h3 className="adsimple-311157787">Welche Arten von Cookies gibt es?</h3>
							<p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
							<p>Man kann 4 Arten von Cookies unterscheiden:</p>
							<p>
								<strong className="adsimple-311157787">Unbedingt notwendige Cookies<br />
								</strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
							<p>
								<strong className="adsimple-311157787">Funktionelle Cookies<br />
								</strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
							<p>
								<strong className="adsimple-311157787">Zielorientierte Cookies<br />
								</strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
							<p>
								<strong className="adsimple-311157787">Werbe-Cookies<br />
								</strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
							<p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
							<h3 className="adsimple-311157787">Wie kann ich Cookies löschen?</h3>
							<p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
							<p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
							<p>
								<a className="adsimple-311157787" href="https://support.google.com/chrome/answer/95647?tid=311157787" target="_blank" rel="noopener noreferrer" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157787" target="_blank" rel="noopener noreferrer" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157787" target="_blank" rel="noopener noreferrer" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Internet Explorer: Löschen und Verwalten von Cookies</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
							</p>
							<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers oder tauschen das Wort &#8220;Chrome&#8221; gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
							<h3 className="adsimple-311157787">Wie sieht es mit meinem Datenschutz aus?</h3>
							<p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
							<p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen, empfehlen wir <a className="adsimple-311157787" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="noopener noreferrer" >https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
							<h2 className="adsimple-311157787">Speicherung persönlicher Daten</h2>
							<p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
							<p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
							<p>Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser Webseite &#8211; können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.</p>
							<p>Die Rechtsgrundlage besteht nach <a className="adsimple-311157787" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311157787" target="_blank" rel="noopener noreferrer" >Artikel 6  Absatz 1 a DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
							<h2 className="adsimple-311157787">Rechte laut Datenschutzgrundverordnung</h2>
							<p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
							<ul className="adsimple-311157787">
								<li className="adsimple-311157787">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
								<li className="adsimple-311157787">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
								<li className="adsimple-311157787">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
								<li className="adsimple-311157787">Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
								<li className="adsimple-311157787">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
								<li className="adsimple-311157787">Widerspruchsrecht (Artikel 21 DSGVO)</li>
								<li className="adsimple-311157787">Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
							</ul>
							<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a className="adsimple-311157787 311157787" href="https://www.bfdi.bund.de" target="_blank" rel="noopener noreferrer" >Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
							<h2 className="adsimple-311157787">Auswertung des Besucherverhaltens</h2>
							<p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
							<p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.</p>
							<h2 className="adsimple-311157787">TLS-Verschlüsselung mit https</h2>
							<p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a className="adsimple-311157787" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&#038;tid=311157787" target="_blank" rel="noopener noreferrer" >Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
							<h2 className="adsimple-311157787">Google Maps Datenschutzerklärung</h2>
							<p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Mit Google Maps können wir Standorte visuell besser darstellen und damit unser Service verbessern. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
							<h3 className="adsimple-311157787">Was ist Google Maps?</h3>
							<p>Google Maps ist ein Online-Kartendienst der Firma Google Inc. Mit Google Maps können Sie im Internet über einen PC oder über eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um  die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts per HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild an. Dank der Street View Bilder und den qualitativ hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
							<h3 className="adsimple-311157787">Warum verwenden wir Google Maps auf unserer Website?</h3>
							<p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Website zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Dank Google Maps sehen Sie auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
							<h3 className="adsimple-311157787">Welche Daten werden von Google Maps gespeichert?</h3>
							<p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Website eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
							<p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
							<ul className="adsimple-311157787">
								<li className="adsimple-311157787">
									<strong className="adsimple-311157787">Name:</strong> NID</li>
								<li className="adsimple-311157787">
									<strong className="adsimple-311157787">Ablaufzeit:</strong> nach 6 Monaten</li>
								<li className="adsimple-311157787">
									<strong className="adsimple-311157787">Verwendung:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, persönliche Einstellungen des Users für Werbezwecke zu sammeln.</li>
								<li className="adsimple-311157787">
									<strong className="adsimple-311157787">Beispielwert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ311157787</li>
							</ul>
							<p>
								<strong className="adsimple-311157787">Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Google nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
							<h3 className="adsimple-311157787">Wie lange und wo werden die Daten gespeichert?</h3>
							<p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a className="adsimple-311157787" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer" >https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
							</p>
							<p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server beeinträchtigt, bleiben die Daten mit hoher Wahrscheinlich dennoch geschützt.</p>
							<p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem sie einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löschen.</p>
							<h3 className="adsimple-311157787">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
							<p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität &#8211; abhängig von Ihrer Entscheidung &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- bzw. ausschalten.</p>
							<p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
							<p>
								<a className="adsimple-311157787" href="https://support.google.com/chrome/answer/95647?tid=311157787" target="_blank" rel="noopener noreferrer" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157787" target="_blank" rel="noopener noreferrer" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157787" target="_blank" rel="noopener noreferrer" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Internet Explorer: Löschen und Verwalten von Cookies</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
							</p>
							<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
							<p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a className="adsimple-311157787" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" rel="noopener noreferrer" >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>. Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a className="adsimple-311157787" href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer" >https://policies.google.com/privacy?hl=de</a>.</p>
							<h2 className="adsimple-311157787">Google Fonts Datenschutzerklärung</h2>
							<p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite.</p>
							<p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
							<h3 className="adsimple-311157787">Was sind Google Fonts?</h3>
							<p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die <a className="adsimple-311157787" href="https://de.wikipedia.org/wiki/Google_LLC?tid=311157787" target="_blank" rel="noopener noreferrer" >Google LLC</a> zur freien Verwendung bereitstellt.</p>
							<p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen. Somit können wir sie frei verwenden, ohne dafür Lizenzgebühren zu zahlen.</p>
							<h3 className="adsimple-311157787">Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
							<p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sogenannte sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser ( Google Chrome, Mozilla Firefox, Apple Safari, Opera)  und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod).</p>
							<p>Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können. Nach dem Art. 6 Abs. 1 f lit. F DSGVO stellt das bereits ein „berechtigtes Interesse“ an der Verarbeitung von personenbezogenen Daten dar. Unter „berechtigtem Interesse“ versteht man in diesem Fall sowohl rechtliche als auch wirtschaftliche oder ideelle Interessen, die vom Rechtssystem anerkannt werden.</p>
							<h3 className="adsimple-311157787">Welche Daten werden von Google gespeichert?</h3>
							<p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um die Erfassung, Speicherung und Verwendung von Endnutzerdaten auf das zu reduzieren, was für eine effiziente Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>
							<p>Google Fonts speichert CSS- und Font-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google die Beliebtheit der Schriften feststellen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. BigQuery ist ein Webservice von Google für Unternehmen, die große Datenmengen bewegen und analysieren wollen.</p>
							<p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie IP-Adresse, Spracheinstellungen, Bildschirmauflösung des Browsers, Version des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
							<h3 className="adsimple-311157787">Wie lange und wo werden die Daten gespeichert?</h3>
							<p>Anfragen für CSS-Assets speichert Google einen Tag lang auf Ihren Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>
							<p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
							<h3 className="adsimple-311157787">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
							<p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a className="adsimple-311157787" href="https://support.google.com/?hl=de&amp;tid=311157787" target="_blank" rel="noopener noreferrer" >https://support.google.com/?hl=de&amp;tid=311157787</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
							<p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="adsimple-311157787" href="https://developers.google.com/fonts/faq?tid=311157787" target="_blank" rel="noopener noreferrer" >https://developers.google.com/fonts/faq?tid=311157787</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig (beinahe unmöglich), von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>
							<p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a className="adsimple-311157787" href="https://policies.google.com/privacy?hl=de&amp;tid=311157787" target="_blank" rel="noopener noreferrer" >https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
							<h2 className="adsimple-311157787">Automatische Datenspeicherung</h2>
							<p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.</p>
							<p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</p>
							<ul className="adsimple-311157787">
								<li className="adsimple-311157787">die Adresse (URL) der aufgerufenen Webseite</li>
								<li className="adsimple-311157787">Browser und Browserversion</li>
								<li className="adsimple-311157787">das verwendete Betriebssystem</li>
								<li className="adsimple-311157787">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
								<li className="adsimple-311157787">den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
								<li className="adsimple-311157787">Datum und Uhrzeit</li>
							</ul>
							<p>in Dateien (Webserver-Logfiles).</p>
							<p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
							<h2 className="adsimple-311157787">Newsletter Datenschutzerklärung</h2>
							<p>
								<span className="adsimple-311157787" >Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter und geben diese nicht weiter.</span>
							</p>
							<p>
								<span className="adsimple-311157787" >Sollten Sie sich vom Newsletter abmelden &#8211; Sie finden den Link dafür in jedem Newsletter ganz unten &#8211; dann löschen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden.</span>
							</p>
							<h2 className="adsimple-311157787">Instagram Datenschutzerklärung</h2>
							<p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.</p>
							<p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Facebook-Datenrichtlinien selbst.</p>
							<h3 className="adsimple-311157787">Was ist Instagram?</h3>
							<p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.</p>
							<h3 className="adsimple-311157787">Warum verwenden wir Instagram auf unserer Webseite?</h3>
							<p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
							<p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.</p>
							<h3 className="adsimple-311157787">Welche Daten werden von Instagram gespeichert?</h3>
							<p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
							<p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Wichtig zu erwähnen ist, dass diese Kundendaten erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat abgeglichen.</p>
							<p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
							<p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a className="adsimple-311157787" href="http://www.instagram.com?tid=311157787" target="_blank" rel="noopener noreferrer" >www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
							<p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
							<p>Diese Cookies wurden bei unserem Test verwendet:</p>
							<p>
								<strong className="adsimple-311157787">Name: </strong>csrftoken<br />
								<strong className="adsimple-311157787">Wert: </strong>&#8220;&#8221;<br />
								<strong className="adsimple-311157787">Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
								<strong className="adsimple-311157787">Ablaufdatum:</strong> nach einem Jahr</p>
							<p>
								<strong className="adsimple-311157787">Name: </strong>mid<br />
								<strong className="adsimple-311157787">Wert: </strong>&#8220;&#8221;<br />
								<strong className="adsimple-311157787">Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
								<strong className="adsimple-311157787">Ablaufdatum:</strong> nach Ende der Sitzung</p>
							<p>
								<strong className="adsimple-311157787">Name:</strong> fbsr_311157787124024<br />
								<strong className="adsimple-311157787">Wert: </strong>keine Angaben<br />
								<strong className="adsimple-311157787">Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong className="adsimple-311157787">
								<br />
							</strong>
								<strong className="adsimple-311157787">Ablaufdatum:</strong> nach Ende der Sitzung</p>
							<p>
								<strong className="adsimple-311157787">Name:</strong> rur<br />
								<strong className="adsimple-311157787">Wert: </strong>ATN<br />
								<strong className="adsimple-311157787">Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
								<strong className="adsimple-311157787">Ablaufdatum:</strong> nach Ende der Sitzung</p>
							<p>
								<strong className="adsimple-311157787">Name:</strong> urlgen<br />

								<strong className="adsimple-311157787">Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
								<strong className="adsimple-311157787">Ablaufdatum:</strong> nach Ende der Sitzung</p>
							<p>
								<strong className="adsimple-311157787">Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
							<h3 className="adsimple-311157787">Wie lange und wo werden die Daten gespeichert?</h3>
							<p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
							<h3 className="adsimple-311157787">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
							<p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
							<p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
							<p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
							<p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.</p>
							<p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.</p>
							<p>
								<a className="adsimple-311157787" href="https://support.google.com/chrome/answer/95647?tid=311157787" target="_blank" rel="noopener noreferrer" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157787" target="_blank" rel="noopener noreferrer" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157787" target="_blank" rel="noopener noreferrer" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Internet Explorer: Löschen und Verwalten von Cookies</a>
							</p>
							<p>
								<a className="adsimple-311157787" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157787" target="_blank" rel="noopener noreferrer" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
							</p>
							<p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
							<p>Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter <a className="adsimple-311157787" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC" target="_blank" rel="noopener noreferrer" >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC </a> erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf <a className="adsimple-311157787" href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer" >https://help.instagram.com/519522125107875</a>
								<br />
								können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
							<p>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland" href="https://www.adsimple.de/datenschutz-generator/" target="_blank" rel="noopener noreferrer follow" >Datenschutz Generator</a> von AdSimple in Kooperation mit <a href="https://www.123familie.de" target="_blank" rel="noopener noreferrer follow" title="">123familie.de</a>
							</p>

					</div>

				</section>

			</main>
			<Footer />
		</div>
	);

}

export default Impressum