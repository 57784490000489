import React, {Component} from "react";
import Vars from "../helpers/Vars";

export default class ProjectComponent extends Component {

	render() {
		let project = this.props.data;
		let image =  project.image.path;
		let image_style = '';
		if(project.image_style !== undefined && project.image_style !== null)
			image_style = project.image_style
		let isHover = project.ishover;
		return (
			<div className={"projectItem" + (isHover && (isHover===true || isHover==="true") ? " hasHover":"")} style={{background: "url('" + Vars.domain + image +"') "+image_style+""}}>
				{isHover && (isHover===true || isHover==="true") && (
					<div className="info">
						<h3>{project.title}</h3>
						<h4><a className="accentHover" href={project.link} target="_blank" rel="noopener noreferrer">{project.linktitle}</a></h4><br />
						<div dangerouslySetInnerHTML={{__html: project.description}} />
					</div>
				)}
			</div>
		);
	}

}
