import React from "react";

import {Link} from "react-router-dom";
import Scrollchor from 'react-scrollchor';

const Footer = () => {

	return (
		<footer>

			<div className="content">

				<Scrollchor to="#mainHeader" className="navLink">
					<div className="goToTopButton">
						<span>&#10094;</span>
					</div>
				</Scrollchor>

				<p>
					&copy; by Daniel Wahl - Created with React and love - <Link to="/impressum">Impressum & Datenschutz</Link>
				</p>
			</div>
		</footer>
	);

}

export default Footer