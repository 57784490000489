import React from "react";
import {Fade} from 'react-reveal';

const Photography = () => {

	return (
		<>
			<section id="photography">
				<div className="content">
					<h2>Photography</h2>
					<div className="grid three-thirds desktop">
						<div className="flex">
							<div className="image">
								<img src="assets/images/photography/milkyZugspitze.jpg" alt="Milkyway Zuspitze" />
							</div>
							<div className="image">
								<img src="assets/images/photography/klamm.jpg" alt="Klamm Bayern" />
							</div>
							<div className="image">
								<img src="assets/images/photography/mosque.jpg" alt="Moschee Abu Dhabi" />
							</div>
						</div>
						<div className="flex">
							<div className="image">
								<img src="assets/images/photography/desert.jpg" alt="Desert Dubai" />
							</div>
							<div className="image">
								<img src="assets/images/photography/croatia.jpg" alt="Krka Waterfall, Croatia" />
							</div>
							<div className="image">
								<img src="assets/images/photography/spain.jpg" alt="Sunset Roses, Spain" />
							</div>
							<div className="image">
								<img src="assets/images/photography/austria.jpg" alt="Austria Waterfall" />
							</div>
						</div>
						<div className="flex">
							<div className="image">
								<img src="assets/images/photography/spain2.jpg" alt="North Spain" />
							</div>
							<div className="image">
								<img src="assets/images/photography/sunsetTrier.jpg" alt="Trier Sunset" />
							</div>
							<div className="image">
								<img src="assets/images/photography/mullerthal.jpg" alt="Müllerthal" />
							</div>
						</div>
					</div>
					<div className="grid half-half keepTwo mobile">
						<div className="flex">
							<div className="image">
								<img src="assets/images/photography/milkyZugspitze.jpg" alt="Milkyway Zuspitze" />
							</div>
							<div className="image">
								<img src="assets/images/photography/klamm.jpg" alt="Klamm Bayern" />
							</div>
							<div className="image">
								<img src="assets/images/photography/mosque.jpg" alt="Moschee Abu Dhabi" />
							</div>
							<div className="image">
								<img src="assets/images/photography/desert.jpg" alt="Desert Dubai" />
							</div>
						</div>

						<div className="flex">
							<div className="image">
								<img src="assets/images/photography/spain.jpg" alt="Sunset Roses, Spain" />
							</div>
							<div className="image">
								<img src="assets/images/photography/trier.jpg" alt="Trier Sunset" />
							</div>
							<div className="image">
								<img src="assets/images/photography/mullerthal.jpg" alt="Müllerthal" />
							</div>
							<div className="image">
								<img src="assets/images/photography/austria.jpg" alt="Austria Waterfall" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);

}

export default Photography