import Vars from './Vars';

class Fetch {

	async fetchProjects() {
		return await fetch(Vars.domain + 'api/collections/get/project', {
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Cockpit-Token': Vars.token },
			body: JSON.stringify({
				filter: {published:true}
			})
		})
			.then(res => res.json())
			.then(res => {
				//console.log(res.entries);
				return res.entries
			});
	}

}

export default Fetch = new Fetch();