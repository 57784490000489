import React from "react";
import ProjectComponent from "./ProjectComponent";
import ProjectBackup from "./ProjectBackup";

const withFetch = true;

const Projects = props => {

	const renderProjects = () => {
		return props.projects.reverse().map((data, i) => (
			<ProjectComponent data={data} key={`project-${i}`}/>
		));
	}

	if(!withFetch) {
		return <ProjectBackup/>
	}

	return (
		<>
			<section id="projects">
				<div className="content">
					<h2>What I've done</h2>
					<div className="grid three-thirds">
						{renderProjects()}
					</div>
				</div>
			</section>
		</>
	);

}

export default Projects