import React, { useState } from "react";
import Vars from "../helpers/Vars";

const Contact = () => {
	const [name, setName] 					= useState("");
	const [email, setEmail] 				= useState("");
	const [message, setMessage] 			= useState("");
	const [isSend, setIsSend] 				= useState(false);
	const [wasSuccessful, setWasSuccessful] = useState(true);

	const [nameError, setNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [messageError, setMessageError] = useState(false);

	const sendMail = async () => {
		await fetch(Vars.domain + 'api/forms/submit/contact', {
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Cockpit-Token': "065cbc9aeec7dbaf1bb55bc3619ac0" },
			body: JSON.stringify({
				form: {
					name: name,
					email: email,
					message: message
				}
			})
		})
			.then(entry => {
				//console.log(entry);
				if(entry.ok || entry.ok === "true") {
					setIsSend(true);
					setWasSuccessful(true);
				} else {
					setIsSend(true);
					setWasSuccessful(false);
				}
			})
			.catch(entry => {
				console.error(entry);
				setIsSend(true);
				setWasSuccessful(false);
			});
		setIsSend(true);
	}

	const validate = async () => {
		let error = false;
		setNameError(false);
		setEmailError(false);
		setMessageError(false);

		console.log("test")

		if(name==="") {
			error = true;
			setNameError(true);
		}

		if(email==="") {
			error = true;
			setEmailError(true);
		}

		if(message==="") {
			error = true;
			setMessageError(true);
		}

		if(!error) {
			await sendMail();
		}
	}

	const showSuccessMessage = () => (
		<div className="contact-form__succes">
			<h3><span className="primary">Thanks</span> for your message, it means a lot to me!</h3>
			<p className="bigger">I will get in touch with you asap.</p>
		</div>
	)

	const showErrorMessage = () => (
		<div className="contact-form__succes">
			<h3>There was an <span className="accent">error</span> sending your message!</h3>
			<p className="bigger">Try again or send me your deepest desire via email.</p>
		</div>
	)

	const showForm = () => (
			<>
				<div className="inputGroup">
					<input type="text"
						   className="contact-form__input"
						   name="name"
						   placeholder="Your lovely name"
						   value={name}
						   onChange={(ev) => setName(ev.target.value)}
					/>
					<span className={"inputGroup__errorMessage" + (nameError?" ig__errorMessage—-show":"")}>
						Please tell me your name.
					</span>
				</div>

				<div className="inputGroup">
					<input type="email"
						   className="contact-form__input"
						   name="email"
						   placeholder="Your precious e-mail address"
						   value={email}
						   onChange={(ev) => setEmail(ev.target.value)}
					/>
					<span className={"inputGroup__errorMessage" + (emailError?" ig__errorMessage—-show":"")}>
						Please tell me your e-mail address.
					</span>
				</div>

				<div className="inputGroup">
					<textarea name="message"
							  className="input contact-form__textarea"
							  placeholder="Your exciting message"
							  value={message}
							  onChange={(ev) => setMessage(ev.target.value)}
					> </textarea>
					<span className={"inputGroup__errorMessage" + (messageError?" ig__errorMessage—-show":"")}>
						I can't help you without a message.
					</span>
				</div>

				<button className="full primary fullWidth" onClick={validate}>
					Tell me your deepest desire
				</button>
			</>

	)

	return (
		<section id="contact">
			<div className="content grid thirty-seventy">
				<div>
					<h2>Get in touch</h2><br />
					<h4>You have an idea? A project in mind? Or want to level up your business with a website?</h4>
					<br /><br />
					<p className="bigger">Feel free to contact me via email: <a className="accentHover" href="mailto: contact@danielwahl.lu">contact(at)danielwahl.lu</a> or use the form.</p>
				</div>
				<div className="contact-form">
					{isSend && wasSuccessful && showSuccessMessage()}
					{isSend && !wasSuccessful && showErrorMessage()}
					{!isSend && showForm()}
				</div>
			</div>
		</section>
	);


}

export default Contact