import React, {Component} from "react";
import ProjectComponent from "./ProjectComponent";

export default class Projects extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoading: true,
		}
	}

	async componentDidMount() {

		await fetch('https://cockpit.portfolio.danielwahl.lu/api/collections/get/Projects', {
			headers: { 'Cockpit-Token': '6d47e7445cbcafb54c85db70938aae'}
		})
			.then(res => res.json())
			.then(res => {
				console.log(res.entries);
				this.setState({data: res.entries, isLoading: false});
			});
	}

	_renderProjects() {
		if(this.state.isLoading) {
			return (
				<p>
					Loading...
				</p>
			);
		}

		return this.state.data.map((data, i) => (
			<ProjectComponent data={data} key={`project-${i}`}/>
		));

	}

	render() {

		return (
			<section id="projects">
				<div className="content">
					<h2>What I've done</h2>
					<div className="grid three-thirds keepTwo">
						<div className="projectItem" style={{background: "url('assets/images/projects/cs7.png')"}}>
							<div className="info">
								<h3>Constructions Siebenaller</h3>
								<h4><a href="http://cs7.lu" target="_blank" rel="noopener noreferrer">cs7.lu</a></h4><br />
								One of my first official clients.<br /><br />
								Build a custom website and a planning webapp, to organise worker groups.<br />
							</div>
						</div>
						<div className="projectItem" style={{background: "url('assets/images/projects/sceer.png')"}}>
							<div className="info">
								<h3>Sceer - Score your beer</h3>
								<h4><a href="https://danielwahl.lu/newtemplate/iphone_app/" target="_blank" rel="noopener noreferrer">Conceptual work</a></h4><br />
								Initially designed for a university course, but I'm currently working on the final app.<br />
								Can be downloaded in App Store in the near future.
							</div>
						</div>
						<div className="projectItem" style={{background: "url('assets/images/projects/karriere_mutterhaus.png')"}}>
							<div className="info">
								<h3>Karriere Mutterhaus</h3>
								<h4><a href="https://karriere-mutterhaus.de" target="_blank" rel="noopener noreferrer">karriere-mutterhaus.de</a></h4><br />
								Developed in cooperation with <a href="https://propeller.de">propeller GmbH</a>.<br />
								Typo3 as Backend CMS, with SCSS, JS, TypoScript and Youtube API.
							</div>
						</div>
						<div className="projectItem" style={{background: "url('assets/images/projects/autopneus.png')"}}>
							<div className="info">
								<h3>Autopneus+</h3>
								<h4><a href="http://autopneus.lu" target="_blank" rel="noopener noreferrer">autopneus.lu</a></h4><br />
								A small and simple website for a luxemburgish tire dealer.<br />
								Fully responsive, with a custom Image slider.
							</div>
						</div>
						<div className="projectItem" style={{background: "url('assets/images/projects/payandpark.png') center 20%"}}>
							<div className="info">
								<h3>Pay and Park</h3>
								<h4><a href="https://pypk.website.danielwahl.lu" target="_blank" rel="noopener noreferrer">Conceptual work</a></h4><br />
								An APP, build with React Native, to rent parking spaces around BeNeLux and Germany.<br />
								Teamproject at Hochschule Trier.
							</div>
						</div>
						<div className="projectItem" style={{background: "url('assets/images/projects/propeller.png') center 70%"}}>
							<div className="info">
								<h3>Other projects</h3>
								<h4><a href="https://propeller.de" target="_blank" rel="noopener noreferrer">propeller.de</a></h4><br />
								Working in cooperation with propeller GmbH since 2019<br />
								And created a lot of wonderful projects together.
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

}